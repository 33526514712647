<template>
     <section class="about">
      <div class="container">
        <div class="section-title">
          <h2>About Us</h2>
          <p>Exceljuta delivers quality experiences while driving greater efficiency and modernizing business operations. We work with people and organizations in their missions by setting up smart teams who love challenges and think disruptively to help our clients succeed. We are based in Chennai, India and have worked with several companies globally ranging from banking to industrial sectors. Our agenda is to deliver high quality performance in the projects we complete. This ensures a steady growth in the companies of our clients.</p>
        </div>
      </div>
    </section>
</template>
<script>
export default {
    
}
</script>
<style>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
  padding: 150px 0px 40px 0px;
  background-image: url("../assets/images/background/about-bg.png");
  position: relative;
}

.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 130px 30px 40px 30px ;
  }
}

</style>