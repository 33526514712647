<template>
    <Header/>
    <About/>
    <Calltoaction/>
    <Client/>
    <History/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Client from '@/components/clientslider.vue'
import About from '@/components/aboutcontent.vue'
import Calltoaction from '@/components/calltoaction.vue'
import History from '@/components/abouthistory.vue'


export default {
  components: {
     Header,
    Footer,
    Client,
    About,
    Calltoaction,
    History
  }
}
</script>