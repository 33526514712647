<template>
     <!-- ======= Why Us Section ======= -->
    <section id="why-us" class="why-us">
      <div class="container-fluid desktopsection">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-12 p-0">
                    <div class="content-left">
                        <img src="../assets/images/background/why-us.jpg" alt="#">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12" style="padding:50px">
                    <div class="content-right" >
                        <h2>History</h2>
                        <p>Exceljuta was formed in the year 2009, a decade ago by a team of highly qualified and experienced professionals from the Enterprise software world.</p>
                        <p>The management team collectively has over 100 years of experience, came together with the goal of leveraging their international industry experience to build a global, comprehensive services organization with an aim to offer the highest quality of services. We are a company offering varied services and we are a group of passionate, purpose-led individuals who believe in developing true partnerships.</p>
                        
                    </div>
                </div>
            </div>
      </div>
        <div class="container-fluid mobilesection">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-12" style="padding:50px">
                    <div class="content-right" >
                        <h2>History</h2>
                        <p>Exceljuta was formed in the year 2009, a decade ago by a team of highly qualified and experienced professionals from the Enterprise software world.</p>
                        <p>The management team collectively has over 100 years of experience, came together with the goal of leveraging their international industry experience to build a global, comprehensive services organization with an aim to offer the highest quality of services. We are a company offering varied services and we are a group of passionate, purpose-led individuals who believe in developing true partnerships.</p>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12 p-0">
                    <div class="content-left">
                        <img src="../assets/images/background/why-us.jpg" alt="#">
                    </div>
                </div>
            </div>
      </div>
      <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-12" style="padding:50px">
                    <div class="content-right" >
                        <h2>What we offer</h2>
                        <p>Overall, we provide our clients with IT, engineering, digital marketing, staffing, property management and technological consulting services.</p>
                        <p>We offer the most cost effective, secure, and scalable models to service our clients across various verticals such as banking, hi-tech, education, and entertainment to name a few.</p>
                        <p>We engineer meaningful technology solutions to help businesses and societies move from performing Good to achieve excellence. Our innovations have allowed us to provide an SLA of anywhere from 0~24hours and the ability to scale over 1,000+ apps a day.</p>
                    </div>
                </div>
                 <div class="col-lg-6 col-md-12 col-12 p-0">
                    <div class="content-left">
                        <img src="../assets/images/background/What-we-offer.jpg" alt="#">
                    </div>
                </div>
            </div>
      </div>
    </section><!-- End Why Us Section -->
</template>
<script>
export default {
   
}
</script>
<style>
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  background: #f1f8ff;
  padding: 0;
}
.content-left > img {
    width: 100%;
    border-radius: 5px;
}
@media only screen and (max-width: 2560px) and (min-width: 995px){
  .mobilesection {
    display: none;
}
}
@media only screen and (max-width: 994px)  and (min-width: 0px){
  .desktopsection {
    display: none;
  }
}

</style>